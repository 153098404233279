import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorKamil as author } from 'data/authors';
import img from 'img/blog/user-roles.png';
import img1 from 'img/blog/userrolesimage.png';

const Content = () => {
  return (
    <Post>
      <p>We’re happy to announce that we’ve answered one of the most common requests!</p>
      <p>
        From now on, you can <strong>assign different user roles</strong> in LiveSession. Your
        teammates can use the app as:
      </p>
      <ul>
        <li>
          <strong>User</strong>– Can watch and analyze sessions. Can’t add a new website, or other
          Users, can’t access Invoices and Plan &amp; Billing. They can only see basic plan info and
          how many sessions are left on the account.
        </li>
        <li>
          <strong>Admin</strong>– Full access, can edit user roles.
        </li>
        <li>
          <strong>Owner</strong>– Full access, can edit user roles. They can’t be removed from the
          account.
        </li>
      </ul>
      <p>Here’s how it looks like in the app:</p>
      <p>
        <img src={img1} alt="Invite your teammate" title="User roles in app" />
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'User roles',
  url: '/blog/user-roles/',
  description: `From now on, you can assign different user roles in LiveSession.`,
  author,
  img,
  imgSocial: img,
  date: '2020-03-26',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
